import { actionTypes } from './reducer';

export const pageSetFetchingHandler = () => ({
  type: actionTypes.PAGE_SET_FETCHING
});

export const pageResetFetchingHandler = () => ({
  type: actionTypes.PAGE_RESET_FETCHING
});

export const pageSetHandler = (page) => ({
  type: actionTypes.PAGE_SET,
  payload: {
    page
  }
});

export const pageSetSavingHandler = () => ({
  type: actionTypes.PAGE_SET_SAVING
});

export const pageResetSavingHandler = () => ({
  type: actionTypes.PAGE_RESET_SAVING
});

export const pageSetSavedHandler = (savedPage) => ({
  type: actionTypes.PAGE_SAVED,
  payload: {
    savedPage
  }
});

export const pageSetMetaHandler = (language, updatedMeta) => ({
  type: actionTypes.PAGE_UPDATE_META,
  payload: {
    language,
    updatedMeta
  }
});

export const pageSetIndexableHandler = (indexable) => ({
  type: actionTypes.PAGE_UPDATE_INDEXABLE,
  payload: {
    indexable
  }
});

export const pageSetSeoFetchingHandler = () => ({
  type: actionTypes.PAGE_SET_SEOS_FETCHING
});

export const pageResetSeoFetchingHandler = () => ({
  type: actionTypes.PAGE_RESET_SEOS_FETCHING
});

export const pageSetSeosHandler = (pageSeos) => {
  return {
    type: actionTypes.PAGE_SEOS_SET,
    payload: {
      pageSeos
    }
  };
};

export const pageSetSeoDeletingHandler = () => ({
  type: actionTypes.PAGE_SET_SEO_DELETING
});

export const pageResetSeoDeletingHandler = () => ({
  type: actionTypes.PAGE_RESET_SEO_DELETING
});

export const pageSetSeoDeleteHandler = (pageSeoId) => {
  return {
    type: actionTypes.PAGE_SEO_DELETE,
    payload: {
      pageSeoId
    }
  };
};

export const pageSetSeoHandler = (pageSeo) => {
  return {
    type: actionTypes.PAGE_SET_SEO,
    payload: {
      pageSeo
    }
  };
};

export const pageInitializeSeoHandler = (languages) => ({
  type: actionTypes.PAGE_SEO_INITIALIZE,
  payload: {
    languages
  }
});

export const pageSeoSetContentHandler = (language, updatedData) => ({
  type: actionTypes.PAGE_SEO_UPDATE_CONTENT,
  payload: {
    language,
    updatedData
  }
});

export const pageSeoSetCloseEditorHandler = () => ({
  type: actionTypes.PAGE_SEO_CLOSE_EDITOR
});

export const pageSeoSetFilterHandler = (filter, value) => ({
  type: actionTypes.PAGE_SEO_UPDATE_FILTER,
  payload: {
    filter,
    value
  }
});

// PAGE SEO SAVE
export const pageSetSeoSavingHandler = () => ({
  type: actionTypes.PAGE_SET_SEO_SAVING
});

export const pageResetSeoSavingHandler = () => ({
  type: actionTypes.PAGE_RESET_SEO_SAVING
});

export const pageSetSeoSaveHandler = (pageSeo) => {
  return {
    type: actionTypes.PAGE_SEO_SAVE,
    payload: {
      pageSeo
    }
  };
};
