import cogoToast from 'cogo-toast';
import { pagesResetLoadingHandler, pagesSetHandler, pagesSetLoadingHandler } from '../actions';

export const PagesQuery = `
  query ($data: PageSearchInputType!) {
    pages(data: $data) {
        id
        path
        indexable
        typeName
        category
        meta {
          id
          description
          language
          title
        }
    }
}`;

export const usePages = (state, dispatch) => {
  const fetchPages = (websiteId) => {
    const variables = {
      data: {
        websiteId: websiteId
      }
    };

    dispatch(pagesSetLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PagesQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const pages = result?.data?.pages;
        if (pages) {
          dispatch(pagesSetHandler(pages));
        } else {
          cogoToast.error('No pages returned from the server.');
        }
        dispatch(pagesResetLoadingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(pagesResetLoadingHandler());
      });
  };

  return {
    loading: state.pagesLoading,
    error: state.pagesError,
    pages: state.pages,
    fetchPages: (websiteId) => fetchPages(websiteId)
  };
};
