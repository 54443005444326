import { pageInitializeSeoHandler, pageSeoSetCloseEditorHandler, pageSeoSetContentHandler, pageSeoSetFilterHandler, pageSetSeoHandler } from '../actions';

export const usePageSeoState = (state, dispatch) => {
  const onEdit = (pageSeo) => {
    dispatch(pageSetSeoHandler(pageSeo));
  };

  const onInitialize = (languages) => {
    dispatch(pageInitializeSeoHandler(languages));
  };

  const onUpdateContent = (language, newContent) => {
    dispatch(pageSeoSetContentHandler(language, newContent));
  };

  const onCloseEditor = () => {
    dispatch(pageSeoSetCloseEditorHandler());
  };

  const onUpdateFilter = (filter, value) => {
    dispatch(pageSeoSetFilterHandler(filter, value));
  };

  return {
    onEdit: (pageSeo) => onEdit(pageSeo),
    onInitialize: (languages) => onInitialize(languages),
    onUpdateContent: (language, newContent) => onUpdateContent(language, newContent),
    onCloseEditor: () => onCloseEditor(),
    onUpdateFilter: (filter, value) => onUpdateFilter(filter, value)
  };
};
