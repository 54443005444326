import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '../../../../App';
import { find } from 'lodash';
import Header from './Header';
import LoadingSkeleton from './LoadingSkeleton';
import PageForm from './PageForm';
import { usePage, usePageSeo, usePageState, usePageSeoState } from '../hooks';
import { isPageWithSeo } from '../../../../utils';
import PageSeo from './PageSeo';
import cogoToast from 'cogo-toast';
import { pageSeoActionTypes } from '../reducer';

const PageEditor = ({ state, dispatch, context }) => {
  const globalState = useContext(StateContext);
  const contextState = useContext(StateContext);
  const currentWebsite = find(contextState?.websites, (w) => w.id === contextState.currentWebsite);
  const languages = globalState?.configuration?.website?.languages;
  const { id: pageIdFromUrl } = useParams();
  const { fetchPage, fetching: fetchingPage, saving: pageSaving, savePage } = usePage(state, dispatch);
  const { onMetaUpdate, onIndexableUpdate } = usePageState(state, dispatch);
  const { fetchPageSeos, fetching: fetchingPageSeos, onDelete: onPageSeoDelete, deleting: pageSeoDeleting, saving: pageSeoSaving, onSave: onPageSeoSave } = usePageSeo(state, dispatch);
  const { onEdit: onPageSeoEdit, onInitialize: onPageSeoInitialize, onUpdateContent: onPageSeoUpdateContent, onUpdateFilter: onPageSeoUpdateFilter, onCloseEditor: onPageSeoEditorClose } = usePageSeoState(state, dispatch);

  useEffect(() => {
    if (currentWebsite) {
      if (pageIdFromUrl) {
        // Fetch page
        fetchPage(pageIdFromUrl, currentWebsite.id, (page) => {
          // Fetch page seo after fetching page if it is page with seo
          if (isPageWithSeo(page)) {
            fetchPageSeos({ pageId: page.id });
          }
        });
      }
    }
  }, [currentWebsite, pageIdFromUrl]);

  if (!currentWebsite || fetchingPage || fetchingPageSeos) {
    return (
      <div className='builder-p-5 builder-w-full'>
        <LoadingSkeleton />
      </div>
    );
  }

  const hasChanges = state.pageHasChanges || state.pageSeoHasChanges;
  const isDuplicatedPageSeoKey = state.pageSeoActionType === pageSeoActionTypes.ADD && find(state.pageSeos, (pageSeo) => pageSeo.key === state.pageSeo.key);

  const handleSave = () => {
    if (hasChanges) {
      const pageId = state?.page?.id;
      // call savePage and as onComplete callback add onPageSeoSave
      // We do not have endpoint to do it in one call, thus it has been done this way
      savePage(() => {
        // If it is a page with seo, save SEO
        if (isPageWithSeo(state.page) && state.pageSeoHasChanges) {
          // Show warning if key already exists
          if (isDuplicatedPageSeoKey) {
            cogoToast.warn('Page SEO with chosen key already exists.');
          } else {
            // Save it if it's new key
            onPageSeoSave(state.pageSeo, pageId, () => {
              cogoToast.success('Page SEO saved successfully.');
            });
          }
        }
      });
    } else {
      cogoToast.info('No changes.');
    }
  };

  return (
    <div data-testid='seo-page-detail-container' className='builder-flex builder-flex-col builder-px-5 builder-pb-5 builder-w-full builder-relative'>
      <Header onSave={handleSave} loading={pageSaving || fetchingPage || fetchingPageSeos || pageSeoDeleting || pageSeoSaving} hasChanges={hasChanges} page={state.page} context={context} />

      <PageForm page={state.page} onMetaUpdate={onMetaUpdate} onIndexableUpdate={onIndexableUpdate} context={context} />

      {isPageWithSeo(state?.page) && (
        <div className='builder-mt-6 builder-flex builder-p-5 builder-w-full builder-bg-gray-50'>
          <PageSeo
            page={state.page}
            pageSeos={state.pageSeos}
            pageSeo={state.pageSeo}
            isDuplicatedPageSeoKey={isDuplicatedPageSeoKey}
            onDelete={onPageSeoDelete}
            deleting={pageSeoDeleting}
            saving={pageSeoSaving}
            onEdit={onPageSeoEdit}
            onInitialize={(e) => onPageSeoInitialize(languages)}
            onCloseEditor={onPageSeoEditorClose}
            onUpdateContent={onPageSeoUpdateContent}
            onUpdateFilter={onPageSeoUpdateFilter}
            context={context}
          />
        </div>
      )}
    </div>
  );
};

export default PageEditor;
