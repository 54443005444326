import {
  pageResetSeoDeletingHandler,
  pageResetSeoFetchingHandler,
  pageResetSeoSavingHandler,
  pageSetSeoDeleteHandler,
  pageSetSeoDeletingHandler,
  pageSetSeoFetchingHandler,
  pageSetSeoSaveHandler,
  pageSetSeoSavingHandler,
  pageSetSeosHandler
} from '../actions';
import cogoToast from 'cogo-toast';
import { map, toString } from 'lodash';
import { filterTypes } from '../reducer';
import { generateKey, serializePageSeo } from '../../utils';

export const PageSeoQuery = `
  query ($data: PageSeoSearchInputType!) {
    pageSeos(data: $data) {
        id
        key
        filters
        content {
          id
          culture
          data
        }
    }
}`;

export const PageSeoDeleteMutation = `
  mutation ($data: PageSeoDeleteInputType!) {
    pageSeoDelete(data: $data) {
        ok
    }
}`;

export const PageSeoSaveMutation = `
  mutation ($data: PageSeoUpdateInputType!) {
    pageSeoUpdate(data: $data) {
        ok
    }
}`;

export const usePageSeo = (state, dispatch) => {
  const fetchPageSeos = ({ pageId, pageSeoId, onComplete }) => {
    let data = {};
    if (pageId) data = { ...data, pageId: pageId };
    if (pageSeoId) data = { ...data, id: pageSeoId };

    const variables = {
      data: data
    };

    dispatch(pageSetSeoFetchingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageSeoQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const pageSeos = result?.data?.pageSeos;
        if (pageSeos) {
          dispatch(pageSetSeosHandler(pageSeos));
        } else {
          cogoToast.error('No data returned from the server.');
        }

        dispatch(pageResetSeoFetchingHandler());

        if (onComplete && typeof onComplete === 'function') {
          onComplete(pageSeos);
        }
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(pageResetSeoFetchingHandler());
      });
  };

  const onDelete = (pageSeoId, onComplete) => {
    const variables = {
      data: {
        id: pageSeoId
      }
    };

    dispatch(pageSetSeoDeletingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageSeoDeleteMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.pageSeoDelete?.ok) {
          dispatch(pageSetSeoDeleteHandler(pageSeoId));
          if (onComplete && typeof onComplete === 'function') {
            onComplete();
          }
        } else {
          cogoToast.error('The seo text not deleted.');
        }

        dispatch(pageResetSeoDeletingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(pageResetSeoDeletingHandler());
      });
  };

  const onSave = (pageSeo, pageId, onComplete) => {
    const variables = {
      data: {
        ...serializePageSeo(pageSeo),
        pageId: pageId
      }
    };

    dispatch(pageSetSeoSavingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageSeoSaveMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const updatedSuccessfully = result?.data?.pageSeoUpdate?.ok;
        if (updatedSuccessfully) {
          dispatch(pageSetSeoSaveHandler(pageSeo));
          if (onComplete && typeof onComplete === 'function') {
            onComplete();
          }
        } else {
          cogoToast.error('The seo could not be saved.');
        }

        dispatch(pageResetSeoSavingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(pageResetSeoSavingHandler());
      });
  };

  return {
    fetchPageSeos: ({ pageId, pageSeoId, onComplete }) => fetchPageSeos({ pageId, pageSeoId, onComplete }),
    fetching: state.pageSeosFetching,
    error: state.pageSeosFetchingError,
    onDelete: (pageSeoId, onComplete) => onDelete(pageSeoId, onComplete),
    deleting: state.pageSeoDeleting,
    deletingError: state.pageSeoDeletingError,
    onSave: (pageSeo, pageId, onComplete) => onSave(pageSeo, pageId, onComplete),
    saving: state.pageSeoSaving,
    savingError: state.pageSeoSavingError
  };
};
